import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Row } from "react-bootstrap"

const ProductsPage = () => (
  <Layout>
    <Seo title="Produtos" />
    <h1>Os nossos produtos</h1>
    <p>Produção 100% artesanal com fruta da época sem corantes nem conservantes artificiais.</p>
    <br/>
    <Row>
      <Col sm={12} md={6}>
        <h4 style={{ textAlign: 'center' }}>Uvada - 350gr e 140gr</h4>
        <div className="w-50 mx-auto">
          <StaticImage src="../images/uvadas.jpg" alt="Uvadas" />
        </div>
      </Col>
      <Col sm={12} md={6}>
        <h4 style={{ textAlign: 'center' }}>Marmelada - 400gr e 150gr</h4>
        <div className="w-50 mx-auto">
          <StaticImage src="../images/marmeladas.jpg" alt="Marmeladas" />
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <h4 style={{ textAlign: 'center' }}>Doces - 140gr</h4>
        <div className="w-50 mx-auto">
          <StaticImage src="../images/doces.jpg" alt="Doces" />
        </div>
      </Col>
    </Row>

  </Layout>
)

export default ProductsPage
